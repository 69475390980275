<template>
  <div class="main-container">
    <Header />

    <AuthorizePurchase />

    <BrandCarousel />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header";
import AuthorizePurchase from "../components/AuthorizePurchase";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

import data from "../data/slider.json";
export default {
  data() {
    return {
      data
    };
  },
  components: {
    Header,
    AuthorizePurchase,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  },
  created() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      //console.log(sessionStorage.getItem("token"));
      if (sessionStorage.getItem("token") != null) {
        axios
          .get(process.env.VUE_APP_AUTH_API + "api/auth/user", {
            headers: {
              Authorization: `Bearer ` + sessionStorage.getItem("token")
            }
          })
          .then(response => {
            //console.log(response.data);
            // console.log(JSON.parse(sessionStorage.getItem("order")));
            if (
              sessionStorage.getItem("items") != null &&
              sessionStorage.getItem("items") != 0
            ) {
              this.cartItemsNumber = sessionStorage.getItem("items");
            }
          })
          .catch(error => {
            this.$swal({
              title: "Sesión finalizada",
              text: "Por favor, inicie sesión nuevamente",
              icon: "info",
              showCancelButton: false,
              showConfirmButton: true
            }).then(result => {
              if (result.isConfirmed) {
                this.$router.push("/login");
              }
            });
            //this.$swal.showLoading();
            this.logout();
            //console.log(error);
          });
      } else {
        this.$swal({
          title: "Iniciar Sesión",
          text: "Por favor, inicia sesión para continuar",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#e94e1b",
          showConfirmButton: true
        }).then(result => {
          if (result.isConfirmed) {
            this.$router.push("/login");
          }
        });
      }
    },
    logout() {
      axios
        .get(urlLogout, {
          headers: {
            Authorization: `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => {
          location.href = "/";
        })
        .catch(error => {
          console.log(error);
          this.error = true;
        });
      sessionStorage.clear();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
</style>

<template>
	<div class="checkout-area" style="padding-top: 50px">
		<div class="container">
			<div class="row">
				<div
					v-if="showForm"
					class="col-md-8 col-lg-8 col-sm-12 col-xs-12 center-block"
					style="margin: auto"
				>
					<form action class="form" @submit.prevent="setFormData()">
						<div class="checkbox-form">
							<h4 style="text-transform: initial">
								Por favor, completa los siguientes datos de tu negocio o comercio. Si no
								posees negocio, haz click en el botón <strong>"Omitir"</strong>
							</h4>
							<div class="row">
								<h3 style="text-transform: initial; text-align: center">
									Datos del Negocio
								</h3>
								<div v-if="activity != 'other'" class="col-md-12">
									<div class="country-select clearfix">
										<label><strong>Actividad económica *</strong> </label>
										<select v-model="activity" required class="form-control">
											<option>Academias comerciales</option>
											<option>Accesorios para coser, hilos, telas, etc</option>
											<option>Accesorios y Artículos Femeninos</option>
											<option>Aeropuertos</option>
											<option>Agencias de Empleo</option>
											<option>Agencias de Festejos</option>
											<option>Agencias de Publicidad</option>
											<option>Agencias de viaje</option>
											<option>Agua</option>
											<option>Aire Acondicionado</option>
											<option>Alfombras, Tapicerias</option>
											<option>Alquiler de Botes y Lanchas</option>
											<option>Alquiler de equipos y Herramientas</option>
											<option>Alquiler de trajes</option>
											<option>Alquiler de Vehículos</option>
											<option>Alquiler de video</option>
											<option>Apuestas, Peñas</option>
											<option>Articulos de Cuero</option>
											<option>Artículos de Zapaterías</option>
											<option>Artículos para Artes Plásticas</option>
											<option>Asesorias Profesionales</option>
											<option>Asoc. cooperativa</option>
											<option>Asociación civil</option>
											<option>Atracciones turisticas</option>
											<option>Autolavado</option>
											<option>Boutique Caballeros</option>
											<option>Boutique Damas</option>
											<option>Campos de Golf</option>
											<option>Canchas de bowling (boliches)</option>
											<option>Carpinteria</option>
											<option>Casa de Empeño</option>
											<option>Casas de Cambio</option>
											<option>Celulares y Accesorios</option>
											<option>Centro de Fotocopiado</option>
											<option>Club</option>
											<option>Combustibles (lubricantes, madera, carbo</option>
											<option>Comida Rapida, Restaurant (sin Bar)</option>
											<option>Condominio Residenciales (Servicios Publicos)</option>
											<option>Construcción</option>
											<option>Contadores y Auditores</option>
											<option>Contratistas de Electricidad</option>
											<option>Corredor de Seguros</option>
											<option>Correo Aereo y Terrestre</option>
											<option>Cortinas, Tapicerias</option>
											<option>Detectives, proteccion, blindados</option>
											<option>Discotecas, Tascas, Bares y similares</option>
											<option>Discotiendas</option>
											<option>Distribuidores y kioskos de peridódicos</option>
											<option>Droguerias y Farmacias</option>
											<option>Dulcerias, Bombonerias y Heladerías</option>
											<option>Electrodomésticos</option>
											<option>Equip. ortopedicos y quirurg.(riñones)</option>
											<option>Equipos agrop. e ind.</option>
											<option>Equipos de Oficina</option>
											<option>Equipos de Salud</option>
											<option>Equipos Electricos</option>
											<option>Escuelas y Colegios</option>
											<option>Escuelas, Academias, Estudios de danza</option>
											<option>Estación de servicio isla</option>
											<option>Estación de servicio tienda</option>
											<option>Estacionamientos</option>
											<option>Estudio Fotográfico</option>
											<option>Ferreterías</option>
											<option>Filatelia y Numismatica</option>
											<option>Financiamiento de Primas de Seguros</option>
											<option>Floristeria</option>
											<option>Funerarias</option>
											<option>Galerias de Arte</option>
											<option>Gas</option>
											<option>Gimnasios</option>
											<option>Gráfica</option>
											<option>Hipertiendas</option>
											<option>Hospitales y Clínicas</option>
											<option>Hoteleria</option>
											<option>Ingenieros y Arquitectos</option>
											<option>Inst. financieras, vta servici</option>
											<option>Instituciones Financieras</option>
											<option>Institutos Tecnológicos</option>
											<option>Instrumentos musicales</option>
											<option>Internet</option>
											<option>Joyeria</option>
											<option>Laboratorios Médicos, Odontológicos</option>
											<option>Latonería y Pintura</option>
											<option>Lavanderia y Limpieza</option>
											<option>Librerías</option>
											<option>Licoreria</option>
											<option>Limpeza de Alfombras y Tapiceria</option>
											<option>Limpieza y Lavado de Ventanas</option>
											<option>Linea aerea internacionales</option>
											<option>Líneas Aereas</option>
											<option>Médicos</option>
											<option>Mudanzas</option>
											<option>Muebles y Accesorios del Hogar</option>
											<option>Muebles y Artículos del Hogar</option>
											<option>Óptcicas</option>
											<option>Optometristas, oftalmologos</option>
											<option>Organizaciones Religiosas</option>
											<option>Organizaciones sin fines de lucro, Beneficencias.</option>
											<option>Ostheopatic physicians</option>
											<option>Pago de Impuestos</option>
											<option>Panaderia, Pasteleria y Cafes</option>
											<option>Papelerias y Utiles Escolares</option>
											<option>Parques de diversiones</option>
											<option>Peajes y Puentes</option>
											<option>Perfumeria, Maquillaje y Cosmeticos</option>
											<option>Periódico</option>
											<option>Plomeria</option>
											<option>Postage stamps</option>
											<option>Productos Petroliferos y a fines</option>
											<option>Productos Quimicos y a fines</option>
											<option>Proveedores de cavas y estantes</option>
											<option>Quiropracticos</option>
											<option>Redes de computacion/serv.de informacion</option>
											<option>Reparacion de articulos Electronicos y similares</option>
											<option>Reparación de Equipos de Audio y Video</option>
											<option>Reproducciones y copias</option>
											<option>Resort</option>
											<option>Restaurant de Lujo (con Bar)</option>
											<option>Ropa de Bebes y Niños</option>
											<option>Ropa Masculina y Femenina</option>
											<option>Ropa para Toda la Familia</option>
											<option>Salas de Cines Teatros y Museo</option>
											<option>
												Salon de Belleza, Peluqueria, Manicuristas, Pedicuristas y
												similares
											</option>
											<option>Salones de billar y pool</option>
											<option>Salones de juego</option>
											<option>Salones de Masajes</option>
											<option>Sastres, Modistas, Arreglos y similares</option>
											<option>Serivicios Médicos en General</option>
											<option>Serv.de transport no clasificados</option>
											<option>Servicicos de Acompañantes</option>
											<option>Servicio de fumigacion</option>
											<option>Servicios de Ambulancias</option>
											<option>Servicios de campo (horticultura)</option>
											<option>Servicios de Computacion</option>
											<option>Servicios de cuidado infantil</option>
											<option>Servicios de Gruas</option>
											<option>Servicios de Piscinas</option>
											<option>
												Servicios de Telecomunicacion Nacional e Internacional
											</option>
											<option>Servicios domésticos</option>
											<option>Servicios gubernamentales no clasificado</option>
											<option>Servicios Legales, Abogados.</option>
											<option>Servicios Odontologicos</option>
											<option>Servicios Veterinarios</option>
											<option>Servicios y accesorios Marinos</option>
											<option>Talleres de aires acond. y eq. de refrig</option>
											<option>Talleres Mecánicos</option>
											<option>Taxis</option>
											<option>Teléfono</option>
											<option>Tienda de Articulos Religiosos</option>
											<option>Tienda de Ropa Deportiva</option>
											<option>Tiendas de Antiguedades</option>
											<option>Tiendas de Artículos deportívos y similares</option>
											<option>Tiendas de Computadoras Software</option>
											<option>Tiendas de delicateses</option>
											<option>Tiendas de Descuentos</option>
											<option>Tiendas de mascotas</option>
											<option>
												Tiendas de Pinturas, Barniz, Papel Tapiz y similares
											</option>
											<option>Tiendas de Productos lácteos</option>
											<option>Tiendas de radios, televisión, eq./sonid</option>
											<option>Tiendas de regalos, tarjeterias</option>
											<option>Tiendas de Variedades</option>
											<option>Tintorerias</option>
											<option>Toldos y persianas</option>
											<option>Transporte de pasajeros (Terrestre y Marítimo)</option>
											<option>Transporte, paradas de camiones</option>
											<option>TV por cable y similares</option>
											<option>U.k petrol stations, electronic hot file</option>
											<option>U.k. supermarkets, electronic hot file</option>
											<option>Uniformes</option>
											<option>Universidades</option>
											<option>Venta de bicicletas</option>
											<option>Ventas de lanchas</option>
											<option>Ventas de motos</option>
											<option>
												Ventas de respuestos para vehículos,camiones,motos y similares
											</option>
											<option>Ventas de vehículos y camiones</option>
											<option>Viveros, Equipos de jardineria y similares</option>
											<option>Zapaterías</option>
											<option>Zapateros</option>
										</select>
									</div>
								</div>
								<div v-if="activity == 'other'" class="col-md-12">
									<div class="checkout-form-list">
										<label><strong>Actividad económica *</strong></label>
										<textarea
											type="text"
											required
											placeholder="Explique brevemente"
											maxlength="140"
											style="width: 100%"
											v-model="activityDetail"
										></textarea>
									</div>
								</div>
								<div class="col-md-12">
									<div class="checkout-form-list">
										<label><strong>Nombre o Razón Social *</strong> </label>
										<input placeholder required v-model="businessName" type="text" />
									</div>
								</div>
								<div class="col-md-12">
									<div class="checkout-form-list">
										<label><strong>Responsable o Representante Legal *</strong> </label>
										<input
											placeholder
											required
											v-model="legalRepresentative"
											type="text"
										/>
									</div>
								</div>
								<div class="col-md-12">
									<div class="checkout-form-list">
										<label
											><strong>RIF</strong>
											<span class="required"></span>
										</label>
										<input placeholder="" v-model="rif" type="text" />
									</div>
								</div>
								<div class="col-md-12">
									<div class="checkout-form-list">
										<label
											><strong>Dirección / Ubicación de Referencia </strong>
											<span class="required">*</span>
										</label>
										<input placeholder="" v-model="address" required type="text" />
									</div>
								</div>
								<h3
									style="text-transform: initial; text-align: center; padding-top: 20px"
								>
									Datos adicionales
								</h3>
								<div class="col-md-12">
									<div class="checkout-form-list">
										<label><strong>Página Web</strong> </label>
										<input placeholder v-model="webPage" type="text" />
									</div>
								</div>
								<hr />
								<div class="col-md-12">
									<div class="row" style="padding: 0px 15px 15px 15px">
										<div class="" style="width: 30%">
											<label><strong>Red Social</strong></label>
											<select
												v-model="socialNetwork"
												class="form-control"
												style="border: 1px solid #e5e5e5"
											>
												<option value="facebook">Facebook</option>
												<option value="instagram">Instagram</option>
												<option value="twitter">Twitter</option>
												<option value="linkedin">LinkedIn</option>
											</select>
										</div>
										<div class="" style="width: 65%; margin-left: 5%">
											<label><strong>Usuario</strong></label>
											<input
												type="string"
												maxlength="100"
												style="
													padding: 6px 6px 6px 0px;
													width: 100%;
													border: 1px solid #e5e5e5;
												"
												v-model="socialNetworkUser"
											/>
										</div>
									</div>
								</div>
								<div class="col-md-12" style="margin: auto">
									<button type="submit" class="btn ht-btn--round">Enviar datos</button>
									<button
										style="margin-left: 20px"
										class="btn ht-btn--round"
										@click.prevent="notFormData()"
									>
										Omitir
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div
					class="col-md-10 col-lg-10 col-sm-12 col-xs-12 center-block"
					style="margin: auto; padding-top: 20px"
				>
					<div class="">
						<h2 class="text-center" style="padding: 30px">Orden por pagar</h2>
						<form action="javascript:void(0)">
							<div class="table-content table-responsive">
								<div v-if="items.length > 0">
									<table class="table">
										<thead>
											<tr>
												<th class="quicky-product-thumbnail">Items</th>
												<th class="cart-product-name">Plataforma</th>
												<!--<th class="quicky-product-price">Precio unitario</th>
                        <th class="quicky-product-quantity">Cantidad</th>
                        <th class="quicky-product-quantity">Total</th>
                        <th class="quicky-product-quantity"></th>-->
											</tr>
										</thead>
										<tbody>
											<tr v-for="item in items" :key="item.id">
												<td>{{ item.item_description }}</td>
												<td>{{ item.plat_id | toPlatformName }}</td>
												<!--<td class="text-right">
                          {{ currency }}
                          {{ parseFloat(item.item_price).toFixed(2) }}
                        </td>
                        <td>{{ item.item_quantity }}</td>
                        <td class="text-right">
                          {{ currency }}
                          {{
                            parseFloat(item.item_price * item.item_quantity).toFixed(2)
                          }}
                        </td>-->
												<td>
													<a
														class="delete"
														title="Elimiar Item"
														@click.prevent="deleteItem(item.id)"
														data-toggle="tooltip"
														><i class="material-icons"></i>
													</a>
												</td>
											</tr>
										</tbody>
									</table>
									<!--<div class="row">
										<div class="col-md-5 ml-auto">
											<div class="cart-page-total" style="padding-bottom: 30px">
												<h2 v-if="subtotal > 0">Resumen</h2>
												<ul v-if="subtotal > 0">
													<li>
														Subtotal
														<span>{{ currency }} {{ subtotal }}</span>
													</li>
													<li>
														IVA ({{ tax }}%)
														<span>{{ currency }} {{ taxAmount }}</span>
													</li>
													<li>
														Total
														<span>{{ currency }} {{ totalAmount }}</span>
													</li>
												</ul>-->
									<!-- <router-link @click="changeStatus" to="#">Pagar</router-link> -->
									<!--</div>
										</div>
									</div>-->
								</div>
								<div v-else>
									<h3
										class="text-center"
										style="padding-top: 30px"
										v-if="tableLength === ''"
									>
										Cargando solicitudes
									</h3>
									<h3
										class="text-center"
										style="padding-top: 30px"
										v-if="tableLength === 0"
									>
										Aún no haz realizado solicitudes
									</h3>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		name: 'AuthorizePurchase',
		data() {
			return {
				config: {
					headers: {
						Authorization: `Bearer ` + sessionStorage.getItem('token'),
					},
				},
				activity: '',
				economicActivity: '',
				activityDetail: '',
				businessName: '',
				legalRepresentative: '',
				webPage: '',
				socialNetwork: '',
				socialNetworkUser: '',
				address: '',
				rif: '',
				showForm: true,
				taxData: '',
				currency: '',
				unitcost: '',
				subtotal: '',
				tax: process.env.VUE_APP_TAX,
				items: [],
				isVerified: false,
				tableLength: '',
			}
		},
		computed: {
			totalAmount() {
				let total = 0
				total = (parseFloat(this.taxAmount) + parseFloat(this.subtotal)).toFixed(2)
				return total
			},
			taxAmount() {
				let tax = 0
				tax = (parseFloat(this.subtotal) * (parseFloat(this.tax) / 100)).toFixed(2)
				return tax
			},
		},
		created() {
			this.checkVerified()
			this.getOrder()
			this.getForMData()
		},
		filters: {
			toPlatformName(platId) {
				if (!platId) return ''
				let platformName
				switch (platId) {
					case 2:
						platformName = 'PayForU'
						break
					case 3:
						platformName = 'MallLikeU'
						break
					case 4:
						platformName = 'Willi-U'
						break
					case 5:
						platformName = 'Ruta-U Negocios'
						break
					case 17:
						platformName = 'Ruta-U Agentes'
						break
					case 6:
						platformName = 'Lili-U'
						break
					case 7:
						platformName = 'U-Konga'
						break
					case 8:
						platformName = 'Euro-ComEx'
						break
					case 9:
						platformName = 'MallLikeU-B2B'
						break
					case 10:
						platformName = 'MallLikeU-B2C'
						break
					case 11:
						platformName = 'MallLikeU-B2C'
						break
					case 18:
						platformName = 'Farma-U'
						break
					case 19:
						platformName = 'Pets-U'
						break
					case 20:
						platformName = 'LikeU-Creative'
						break
					default:
						platformName = platId
						break
				}
				return platformName
			},
		},
		methods: {
			async changeActivity() {
				if (this.activity == 'other') {
					this.economicActivity = this.activityDetail
				} else {
					this.economicActivity = this.activity
				}
			},
			async getForMData() {
				axios
					.get(process.env.VUE_APP_AUTH_API + 'api/user/business', this.config)
					.then((response) => {
						this.showForm = true
						if (
							response.data.economicactivity == 'charity' ||
							response.data.economicactivity == 'retail' ||
							response.data.economicactivity == 'service' ||
							response.data.economicactivity == 'software' ||
							response.data.economicactivity == null
						) {
							this.activity = response.data.economicactivity
						} else {
							this.activity = 'other'
							this.activityDetail = response.data.economicactivity
						}
						this.businessName = response.data.businessname
						this.legalRepresentative = response.data.businessrepresentative
						this.address = response.data.businessaddress
						this.webPage = response.data.webpage
						this.rif = response.data.businessrif
						this.taxData = response.data.taxdata
						this.socialNetwork = response.data.socialnetwork
						this.socialNetworkUser = response.data.socialnetworkuser

						if (
							response.data.businesscheck == 'to be checked' &&
							response.data.businessname != null
						) {
							this.$swal({
								title: `Hemos recibido los datos de tu negocio`,
								html: `Atenderemos tu solicitud y en las próximas horas nuestro equipo de atención al cliente te contactará para darte más información`,
								showCancelButton: false,
								confirmButtonColor: '#e94e1b',
								showConfirmButton: true,
								confirmButtonText: 'Aceptar',
							}).then((result) => {
								this.$router.push('/')
							})
						} else if (response.data.businesscheck == 'verified') {
							this.$swal({
								title: `Hemos verificado los datos de tu negocio satisfactoriamente`,
								html: `Nuestro equipo de atención al cliente te contactará para darte más detalles sobre la afiliación.`,
								showCancelButton: false,
								confirmButtonColor: '#e94e1b',
								showConfirmButton: true,
								confirmButtonText: 'Aceptar',
							}).then((result) => {
								if (this.totalAmount > 0) {
									this.$router.push('/pagar')
								}
							})
						} else if (response.data.businesscheck == 'rejected') {
							this.showForm = false
							this.$swal({
								title: `Lamentamos informarte que tu solicitud de afiliación fue rechazada`,
								html: `Nuestro equipo de atención al cliente te contactará para darte más detalles.`,
								showCancelButton: false,
								confirmButtonColor: '#e94e1b',
								showConfirmButton: true,
								confirmButtonText: 'Aceptar',
							})
						}
					})
					.catch((error) => {
						console.log(error.response)
						this.error = true
					})
			},
			notFormData() {
				axios
					.post(
						process.env.VUE_APP_AUTH_API + 'api/user/business',
						{
							economicactivity: 'N/A',
							businessname: 'N/A',
							businessrepresentative: 'N/A',
							businessaddress: 'N/A',
							businessaddress: 'N/A',
							webpage: 'N/A',
							businessrif: 'N/A',
							taxdata: 'N/A',
							socialnetwork: 'N/A',
							socialnetworkuser: 'N/A',
						},
						{
							headers: {
								Authorization: `Bearer ` + sessionStorage.getItem('token'),
								'content-type': 'application/json',
							},
						}
					)
					.then((response) => {
						//console.log(response.data);
						this.$swal({
							title: `¡Hola ` + sessionStorage.getItem('name') + `!`,
							html: `Hemos recibido tu solicitud, nuestro equipo de Atención al Cliente te contactará en las próximas horas.`,
							showCancelButton: false,
							confirmButtonColor: '#e94e1b',
							showConfirmButton: true,
							confirmButtonText: 'Aceptar',
						}).then((result) => {
							location.reload()
						})
					})
					.catch((error) => {
						console.log(error)
						this.error = true
					})
			},
			async setFormData() {
				this.changeActivity()
				axios
					.post(
						process.env.VUE_APP_AUTH_API + 'api/user/business',
						{
							economicactivity: this.economicActivity,
							businessname: this.businessName,
							businessrepresentative: this.legalRepresentative,
							businessaddress: this.address ? this.address : 'n/a',
							webpage: this.webPage ? this.webPage : 'n/a',
							businessrif: this.rif ? this.rif : 'n/a',
							taxdata: this.taxData ? this.taxData : 'n/a',
							socialnetwork: this.socialNetwork ? this.socialNetwork : 'n/a',
							socialnetworkuser: this.socialNetworkUser ? this.socialNetworkUser : 'n/a',
						},
						{
							headers: {
								Authorization: `Bearer ` + sessionStorage.getItem('token'),
								'content-type': 'application/json',
							},
						}
					)
					.then((response) => {
						//console.log(response.data);
						this.$swal({
							title: `¡Hola ` + sessionStorage.getItem('name') + `!`,
							html: `Hemos recibido tu solicitud, verificaremos los datos de tu negocio. <br>
                    Nuestro equipo de Atención al Cliente te contactará en las próximas horas.`,
							showCancelButton: false,
							confirmButtonColor: '#e94e1b',
							showConfirmButton: true,
							confirmButtonText: 'Aceptar',
						}).then((result) => {
							this.$router.push('/')
						})
					})
					.catch((error) => {
						console.log(error)
						this.error = true
					})
			},
			async deleteItem(itemId) {
				this.$swal({
					position: 'center',
					title: '¿Desea eliminar?',
					confirmButtonColor: '#41b882',
					cancelButtonColor: '#ff7674',
					showCancelButton: true,
					icon: 'info',
					confirmButtonText: 'Si',
					cancelButtonText: 'No',
					showCloseButton: true,
					showLoaderOnConfirm: true,
				}).then((result) => {
					if (result.value) {
						axios
							.delete(process.env.VUE_APP_CART_API + 'api/items/' + itemId, this.config)
							.then(() => {
								this.getOrder()
								this.$swal({
									position: 'center',
									title: 'Solicitud eliminada',
									showConfirmButton: false,
									timer: 1500,
									icon: 'success',
								})
							})
							.catch((error) => {
								this.$swal({
									position: 'center',
									title: 'Ocurrió un error',
									html:
										'<h3 class="text-center">Compruebe su conexión a internet e intente nuevamente</h3>',
									icon: 'error',
									showConfirmButton: true,
									confirmButtonText: 'Aceptar',
								}).then((result) => {
									if (result.isConfirmed) {
										location.reload()
									}
								})
								this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`)
								console.log(error.response)
								this.error = true
							})
					}
				})
			},
			async getOrder() {
				axios
					.get(process.env.VUE_APP_CART_API + 'api/order-to-pay/12', this.config)
					.then((response) => {
						//console.log(response.data.order);
						this.currency = response.data.order.orde_currency
						this.items = response.data.order.items
						this.subtotal = parseFloat(response.data.order.orde_total).toFixed(2)

						if (response.data.order) {
							this.tableLength = response.data.order.items.length
						}
					})
					.catch((error) => {
						console.log(error.response)
						this.error = true
					})
			},
			async checkVerified() {
				let urlCheckVerified = process.env.VUE_APP_AUTH_API + 'api/verification/user'
				axios
					.get(urlCheckVerified, this.config)
					.then((response) => {
						//console.log(response.data);
						if (
							response.data.verification == 'to be checked' ||
							response.data.verification == 'verified'
						) {
							this.isVerified = true
						} else {
							this.isVerified = false
							this.$swal({
								imageUrl: 'https://vistapointe.net/images/lock-4.jpg',
								imageWidth: 200,
								title: `¡Hola ` + sessionStorage.getItem('name') + `!`,
								html: `Antes de continuar, iniciaremos un proceso rutinario de verificación de datos. <br>
                Esto nos permite mantener una relacion más segura contigo`,
								showCancelButton: false,
								confirmButtonColor: '#e94e1b',
								showConfirmButton: true,
								confirmButtonText: 'Aceptar',
							}).then((result) => {
								if (result.isConfirmed) {
									this.$router.push({
										name: 'VerifyUser',
										query: {
											email: response.data.email,
										},
									})
								}
							})
						}
					})
					.catch((error) => {
						/*this.$swal({
            title: "Parece que ocurrió un error...",
            text: "Comprueba tu conexión de internet e intenta de nuevo",
            showCancelButton: false,
            showConfirmButton: true
          }).then(result => {
            if (result.isConfirmed) {
              location.reload();
            }
          });
          this.$swal.showValidationMessage(error);*/
						console.log(error)
						this.error = true
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/main.scss';

	.checkout-area {
		padding: 40px 0;
	}

	.quicky-cart-area {
		padding: 20px 0;
	}

	.ht-btn--round {
		padding: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-top: 20px;
		font-size: 20px;
		background-color: #e94e1b;
	}

	.table-content table td {
		border-top: medium none;
		padding: 18px 10px;
		vertical-align: middle;
		font-size: 17px;
	}

	.table-content table th {
		font-size: 18px;
		font-weight: bold;
	}

	.checkbox-form .checkout-form-list {
		margin-bottom: 10px;
	}
</style>
